import React, { Component, cloneElement } from 'react';
import classnames from 'classnames';
import type Toggler from './toggler';
import './toggle.scss';

class Toggle extends Component<Props> {

    static defaultProps = {
        isInline: false,
        isOpen: false,
        onToggle: () => {},
        transitionToggle: false
    }

    constructor(props) {
        super(props);

        this.toggleState = this.toggleState.bind(this);
    }

    state = {
        isOpen: this.props.isOpen, // eslint-disable-line react/destructuring-assignment
        isOpenTransition: this.props.transitionToggle // eslint-disable-line react/destructuring-assignment
    }

    getClassName(isInline) {
        const { isOpen, isOpenTransition } = this.state;

        return classnames({
            'inline': isInline,
            'open': isOpen,
            'open-transition': isOpenTransition,
            'toggle': true
        });
    }

    toggleState() {
        const { onToggle, transitionToggle } = this.props;
        let { isOpen } = this.state;
        isOpen = !isOpen;
        onToggle({ isOpen });
        this.setState({
            isOpen,
            ...transitionToggle && { isOpenTransition: isOpen },
        });
    }

    render() {
        const { children, isInline } = this.props;
        const elements = children instanceof Array
            ? children.filter(React.isValidElement) : [];

        return (
            <div className={this.getClassName(isInline)} data-testid="toggle">
                {elements.map((child, index) => (
                    cloneElement(child, { key: index, ...index === 0 && {
                        onClick: this.toggleState
                    } as React.ComponentProps<typeof Toggler>})
                ))}
            </div>
        );
    }
}

interface Props {
    children: React.ReactNode;
    isInline: boolean;
    isOpen: boolean;
    onToggle: (arg: { isOpen: boolean }) => void;
    transitionToggle: boolean;
}

export default Toggle;
