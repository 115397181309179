import './framework/prebid/client/vendor/prebid';

import {
    WindowWithPbjs,
    WindowWithClientData,
    WindowWithDataLayer,
    WindowWithGa,
    WindowWithGoogleTag,
    WindowWithAdvertising,
} from '@gumtree/shared/src/types/window';
import { getCurrentBreakpoint } from '@gumtree/shared/src/util/breakpoints-service';
import {
    convertExperimentsToStringArray,
    getExperiments,
} from '@gumtree/shared/src/util/experiments-service';
import { sanitiseToOnlyAlphanumeric } from '@gumtree/shared/src/util/string-sanitise';

import initLiveRamp from './framework/liveramp/init';
import dfpTrackBrowserType from './framework/dfp/browser-key-value-builder';
import dfpTrackScreenResolution from './framework/dfp/screen-resolution-key-value';
import dfpTrackTallMobile from './framework/dfp/tall-mobile-key-value';
import dfpTrackBelow360px from './framework/dfp/below-360px-key-value';
import dfpBffSrpResultsSize from './framework/dfp/results-size-key-value';
import loadDisplayAds from './framework/dfp/init-display-ads';
import startPrebid from './framework/prebid/start-prebid';
import { loadBingAds } from './framework/bing';
import trackAdsPerformance from './framework/track-ads-performance';
import adjustPremiumSlots from './framework/dfp/adjust-premium-slots';
import textLinkSlotAdjust from './framework/dfp/text-link-slot-adjust';
import mobileSkinsAnimation from './framework/dfp/mobile-skins-animation';
import getUrlParameter from './framework/util/extract-url-parameter';
import suppressTextAdSpace from './framework/suppress-text-ad-space';
import { DataLayer } from './framework/bing/client-side/loader';
import initMicrosoftClarity from './framework/microsoft-clarity/init';

const getPageData = (window: WindowWithClientData) => ({
    device: window.clientData.baseConfig.device.type,
    experiments: window.clientData.experiments,
    pageType: window.clientData.baseConfig.pageType,
    breadcrumbs: window.clientData.breadcrumbs,
    googlePpcVisit: getUrlParameter(window.location.search, 'utm_medium') === 'cpc',
    criteoPpcVisit: getUrlParameter(window.location.search, 'utm_source') === 'Criteo',
});

const initDisplay = async (
    window: WindowWithGoogleTag &
        WindowWithDataLayer &
        WindowWithClientData &
        WindowWithGa &
        WindowWithAdvertising
) => {
    const { device, experiments, pageType } = getPageData(window);

    const hashedUserIdFromDataLayer =
        window.dataLayer &&
        window.dataLayer[0] &&
        window.dataLayer[0].d &&
        window.dataLayer[0].d.ck;

    const l1Category = window.clientData.displayAds?.config?.l1Category;
    const l2Category = window.clientData.displayAds?.config?.l2Category;

    const serverConfigs = {
        ...window.clientData.displayAds,
        ...window.clientData.displayAds?.config,
    };

    if (serverConfigs?.targeting?.ptg) {
        serverConfigs.targeting.ptg = convertExperimentsToStringArray(experiments);
    }

    await loadDisplayAds(
        window.googletag,
        window,
        serverConfigs,
        window.clientData.displayAds?.config?.resultsSize,
        l1Category,
        l2Category ? l2Category : null,
        pageType,
        device,
        experiments,
        hashedUserIdFromDataLayer
    );

    // post initialising display
    dfpTrackTallMobile(window.googletag);
    dfpTrackBelow360px(window.googletag);
    dfpBffSrpResultsSize(window.googletag, window, pageType);
    mobileSkinsAnimation(device, pageType, l1Category, l2Category, experiments, window);
    adjustPremiumSlots(l1Category, window);
    textLinkSlotAdjust(window);
    dfpTrackBrowserType(window.googletag);
    dfpTrackScreenResolution(window.googletag);

    trackAdsPerformance({ googletag: window.googletag, ga: window.ga, performance });
};

const initPrebid = async (window: WindowWithGoogleTag & WindowWithPbjs & WindowWithClientData) => {
    const { device, pageType, experiments } = getPageData(window);

    const l1Category = window.clientData.displayAds?.config?.l1Category;
    const l2Category = window.clientData.displayAds?.config?.l2Category;
    const l3Category = window.clientData.displayAds?.config?.targeting?.l3;

    const screenSize = getCurrentBreakpoint(window)?.tshirt;

    await startPrebid(
        window.googletag,
        pageType,
        device,
        screenSize,
        l1Category,
        l2Category,
        l3Category,
        '',
        convertExperimentsToStringArray(experiments),
        window.clientData.displayAds,
        window
    );
};

export const initBing = async (window: WindowWithClientData) => {
    const bingConfig = window.clientData?.bingAds;
    if (!bingConfig) {
        return;
    }

    const { device, pageType, experiments, googlePpcVisit, breadcrumbs } = getPageData(window);

    const breadCrumbLeaf = breadcrumbs?.links?.length
        ? breadcrumbs.links[breadcrumbs.links.length - 1].name
        : '';
    const sanitisedBreadCrumbLeaf = sanitiseToOnlyAlphanumeric(breadCrumbLeaf);

    const l1Category = bingConfig.l1Category;
    const l2Category = bingConfig.l2Category;
    const keywords =
        pageType === 'ResultsBrowse' && sanitisedBreadCrumbLeaf
            ? sanitisedBreadCrumbLeaf
            : bingConfig.keywords;
    const clientIp = bingConfig.clientIp;
    const resultSize = bingConfig.resultsSize as number;

    await loadBingAds(
        device,
        keywords,
        clientIp,
        l1Category,
        l2Category,
        resultSize,
        convertExperimentsToStringArray(experiments),
        googlePpcVisit,
        pageType,
        window.dataLayer as unknown as DataLayer,
        window
    );
};

const initAds = (
    window: WindowWithGoogleTag &
        WindowWithPbjs &
        WindowWithClientData &
        WindowWithDataLayer &
        WindowWithGa &
        WindowWithAdvertising
) => {
    if (!window.clientData) {
        throw new Error('clientData not found on window object. Error id: 1uw2');
    }

    const mobileAdHoldOutVariantC =
        window.clientData.baseConfig?.device?.type === 'mobile' &&
        getExperiments('GTALL-24688') === 'C';

    if (mobileAdHoldOutVariantC) {
        suppressTextAdSpace(window.clientData?.baseConfig?.pageType);
        return;
    }

    const desktopAdHoldOutVariantD =
        window.clientData.baseConfig?.device?.type === 'desktop' &&
        getExperiments('GTALL-24643') === 'D';

    if (!desktopAdHoldOutVariantD) {
        const userEmail = window.clientData.userData?.email;
        initLiveRamp(window, userEmail);
    }

    initMicrosoftClarity(window);

    if (desktopAdHoldOutVariantD) {
        Promise.all([initBing(window)]);
    } else {
        Promise.all([initBing(window), initDisplay(window), initPrebid(window)]);
    }
};

export default initAds;
