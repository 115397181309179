import { ExperimentNames } from '@gumtree/shared/src/model/experiment';

export default function userSubMenuData(
    buyerUrl: string,
    sellerUrl: string,
    experiments: { [key: string]: string }
) {
    const inExperimentGTP2827 = experiments?.[ExperimentNames.MANAGE_ADS_BFF] === 'B';

    const subMenuData = [
        {
            url: `${inExperimentGTP2827 ? buyerUrl : sellerUrl}/manage/ads`,
            label: 'Manage my Ads',
            icon: 'folder',
            eventAction: 'HeaderDropdownSelect',
            eventLabel: 'ManageAds',
        },
        {
            url: '/manage/messages',
            label: 'Messages',
            icon: 'envelope',
            eventAction: 'HeaderDropdownSelect',
            eventLabel: 'MyMessages',
        },
        {
            url: '/my-account/favourites',
            label: 'Favourites',
            icon: 'full-heart',
            eventAction: 'HeaderDropdownSelect',
            eventLabel: 'Favourites',
        },
        {
            url: '/my-account/saved-searches',
            label: 'My Alerts',
            icon: 'bell',
            eventAction: 'HeaderDropdownSelect',
            eventLabel: 'SavedSearches',
        },
        {
            url: `${sellerUrl}/manage-account/`,
            label: 'My Details',
            icon: 'person',
            eventAction: 'HeaderDropdownSelect',
            eventLabel: 'MyAccount',
        },
        {
            url: 'https://recruiters.gumtree.com/job-listing/',
            label: 'Manage my Job Ads',
            icon: 'tie',
            eventAction: 'HeaderDropdownSelect',
            eventLabel: 'ManageMyJobAds',
        },
        {
            url: 'https://help.gumtree.com',
            label: 'Help & Contact',
            icon: 'circ-question-mark',
            eventAction: 'HeaderDropdownSelect',
            eventLabel: 'Help',
        },
    ];

    return subMenuData;
}
