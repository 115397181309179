/* eslint-disable no-underscore-dangle */
const isCreativeAnnotation = (ext) => ext._type === 'Ads/CreativeAnnotation';

export const hasCreativeAnnotation = (extensions = []) => extensions.some(isCreativeAnnotation);

export const getCreativeAnnotation = (extensions = []) => extensions.find(isCreativeAnnotation);

export const getCreativeAnnotationOverrides = ({
    overrides: [
        { title = '', description = '' },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ...rest
    ],
} = {}) => ({ title, description });
