const suppressTextAdSpace = (pageType) => {
    if (!pageType) return;

    if (['ResultsSearch', 'ResultsBrowse'].includes(pageType)) {
        const slot12 = document.querySelector('#slot12');
        if (slot12) {
            slot12.style.display = 'none';
        }

        const slot15 = document.querySelector('#slot15');
        if (slot15.childElementCount === 0 && slot15.closest('.sponsored-link-spot')) {
            slot15.closest('.sponsored-link-spot').style.display = 'none';
        }
    }

    if (['VIP', 'eVIP', 'pVIP'].includes(pageType)) {
        const slot12 = document.querySelector('#slot12');
        if (
            slot12.childElementCount === 0 &&
            slot12.closest('.adsense-slot') &&
            slot12.closest('.adsense-slot').parentElement
        ) {
            slot12.closest('.adsense-slot').parentElement.style.display = 'none';
        }
    }
};

export default suppressTextAdSpace;
