import smoothScroll from 'smoothscroll-polyfill';
import React, { Component, createRef } from 'react';
import cx from 'classnames';

const scrollDistance = 380;

interface Props {
    onScrollLeftClick: () => void;
    onScrollRightClick: () => void;
    children: React.ReactElement<any>;
}

interface State {
    isLeftChevronActive: boolean;
    isRightChevronActive: boolean;
}

class WithScroll extends Component<Props, State> {
    reel: React.RefObject<HTMLDivElement>;
    constructor(props) {
        super(props);
        this.state = {
            isLeftChevronActive: false,
            isRightChevronActive: false,
        };

        this.reel = createRef();
        this.onScrollLeftClick = this.onScrollLeftClick.bind(this);
        this.onScrollRightClick = this.onScrollRightClick.bind(this);
        this.updateChevronStateEagerly = this.updateChevronStateEagerly.bind(this);
    }

    componentDidMount() {
        smoothScroll.polyfill();
        this.updateChevronStateEagerly();
    }

    onScrollLeftClick() {
        this.updateChevronStateEagerly(-scrollDistance);
        if (this.reel.current) {
            this.reel.current.scrollBy({
                left: -scrollDistance,
                behavior: 'smooth',
            });
        }

        const { onScrollLeftClick } = this.props;

        onScrollLeftClick();
    }

    onScrollRightClick() {
        this.updateChevronStateEagerly(scrollDistance);
        if (this.reel.current) {
            this.reel.current.scrollBy({
                left: scrollDistance,
                behavior: 'smooth',
            });
        }

        const { onScrollRightClick } = this.props;

        onScrollRightClick();
    }

    updateChevronStateEagerly(scrollAmount = 0) {
        if (this.reel.current) {
            const reel = this.reel.current;
            const scrollLeft = reel.scrollLeft + scrollAmount;
            const elementWidth = reel.offsetWidth;
            const scrollWidth = reel.scrollWidth;
            const isAtScrollMin = scrollLeft <= 0;
            const isAtScrollMax = scrollWidth - elementWidth - scrollLeft <= 0;

            this.setState({
                isLeftChevronActive: !isAtScrollMin,
                isRightChevronActive: !isAtScrollMax,
            });
        }
    }

    render() {
        const { isLeftChevronActive, isRightChevronActive } = this.state;
        const { children } = this.props;

        const leftChevronClassNames = cx('btn-navigation', { 'is-disabled': !isLeftChevronActive });
        const rightChevronClassNames = cx('btn-navigation', {
            'is-disabled': !isRightChevronActive,
        });

        // Clone the single React element child, and add the ref prop to it
        const childWithRef = React.cloneElement(React.Children.only(children), {
            ref: this.reel,
        });

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                    className={leftChevronClassNames}
                    data-testid="icn-chevron-l"
                    role="button"
                    tabIndex={0}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' || event.key === ' ') {
                            this.onScrollLeftClick();
                        }
                    }}
                    onClick={this.onScrollLeftClick}
                >
                    <span className="icn-chevron-l icon--chevron-l icon" />
                </div>
                {childWithRef}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                    className={rightChevronClassNames}
                    data-testid="icn-chevron-r"
                    role="button"
                    tabIndex={0}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' || event.key === ' ') {
                            this.onScrollLeftClick();
                        }
                    }}
                    onClick={this.onScrollRightClick}
                >
                    <span className="icn-chevron-r icon--chevron-r icon" />
                </div>
            </div>
        );
    }
}

export default WithScroll;
