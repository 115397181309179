import styled from '@emotion/styled';
import {
    colors,
    gutterSizes,
    fontSizes,
} from '@gumtree/ui-library/src/base/theme';
import FacebookLogoSvg from '@gumtree/shell/src/footer/social-icons/facebook-logo.svg';
import FacebookLogoPng from '@gumtree/ui-library/src/assets/Facebook_Logo_Primary.png';

export const StyledSocialFormWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${gutterSizes.large18};
`;

export const StyledErrorBox = styled.div`
    background-color: ${colors.branch10};
    padding: ${gutterSizes.medium};
`;

export const StyledErrorTitle = styled.div`
    display: flex;
    align-items: center;
    gap: ${gutterSizes.small};
    margin-bottom: ${gutterSizes.small};
    color: ${colors.red};
    font-size: ${fontSizes.large};
`;

export const StyledErrorDescription = styled.span`
    font-size: ${fontSizes.base};
`;

export const StyledGoogleButtonWrapper = styled.div`
    max-width: 400px;
    min-width: 200px;
`;

export const StyledFacebookButton = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-width: 400px;
    width: 100%;
    padding: ${gutterSizes.base} ${gutterSizes.medium};
    background: ${colors.facebook};
    border: 1px solid ${colors.facebook};
    border-radius: 2px;
    font-size: ${fontSizes.medium};
    color: ${colors.white};
    cursor: pointer;

    &:hover {
        background-color: ${colors.bark};
    }
`;

export const StyledFacebookLogoPng = styled.div`
    background-image: url('${FacebookLogoPng}');
    background-size: cover;
    height: 24px;
    width: 24px;
    margin-right: ${gutterSizes.base};
`;

export const StyledFacebookLogoSvg = styled(FacebookLogoSvg)`
    position: absolute;
    top: 50%;
    left: ${gutterSizes.medium};
    transform: translateY(-50%);
`;

export const StyledForm = styled.form`
    display: none;
`;
