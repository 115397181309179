const PROD_ORIGIN = 'https://www.gumtree.com';
const GATEWAY_ORIGIN = 'https://gateway.gumtree.com';
const QA_ORIGIN_REGEXP = /^https:\/\/(?:www|gateway)\.\S+\.gumtree\.io$/;

export function validateOrigin(window: Window, origin: string) {
    const { isQA } = window.clientData?.baseConfig ?? {};

    return (
        [PROD_ORIGIN, GATEWAY_ORIGIN].includes(origin) || (isQA && QA_ORIGIN_REGEXP.test(origin))
    );
}

export function getTargetOrigin(window: Window) {
    const { domain } = window.clientData?.baseConfig ?? {};
    if (domain) {
        return `https://www.${domain}`;
    }
    return PROD_ORIGIN;
}
