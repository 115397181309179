/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import classnames from 'classnames';

import { trackGA4AndNavigate } from '@gumtree/shared/src/util/ga4-shared';
import { getDomainFromUrl } from '@gumtree/shared/src/util/url-service';
import { Icon, SubMenu, SubMenuItem } from '@gumtree/ui-library';
import slug from '@gumtree/ui-library/src/utils/slug';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { trackV2 } from '@gumtree/shared/src/util/track-ga-event';
import LinkButton from './link-button';
import getMenuItems from './user-sub-menu-data';
import LogoutForm from '../logout-form';

import './user-sub-menu.scss';

export default function UserSubMenu({ buyerUrl, sellerUrl, isOpen = false, experiments }: Props) {
    const menuItems = React.useMemo(() => getMenuItems(buyerUrl, sellerUrl, experiments), []);

    return (
        <div
            data-testid="user-sub-menu"
            className={classnames({ 'user-sub-menu': true, open: isOpen })}
            {...qaAttribute('user-sub-menu')}
        >
            <SubMenu>
                {menuItems.map(({ label, url, eventAction, eventLabel }) => (
                    <SubMenuItem key={label}>
                        <LinkButton
                            isPadded
                            url={url}
                            {...qaAttribute(`user-menu-link-${slug(label)}`)}
                            onClick={(e) => {
                                eventAction && trackV2(eventAction, eventLabel);
                                trackGA4AndNavigate<GA4.ClickPrimaryNavigation>({
                                    href: url,
                                    mouseEvent: e,
                                    payload: {
                                        event: 'click_primary_navigation',
                                        linkDomain: getDomainFromUrl(url),
                                        linkText: label,
                                        linkURL: url,
                                        navigationCategory: 'menu',
                                        navigationHeader: undefined,
                                        navigationHierarchy: 'sub header',
                                    },
                                });
                            }}
                        >
                            {label}
                            <Icon type="chevron-r" />
                        </LinkButton>
                    </SubMenuItem>
                ))}
                <SubMenuItem>
                    <LogoutForm />
                </SubMenuItem>
            </SubMenu>
        </div>
    );
}

interface Props {
    buyerUrl: string;
    sellerUrl: string;
    isOpen?: boolean;
    experiments: { [key: string]: string };
}
