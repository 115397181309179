/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, MouseEventHandler } from 'react';
import { useSelector as useSelectorHook, useDispatch } from 'react-redux';

import { Icon } from '@gumtree/ui-library';
import { Props as ButtonProps } from '@gumtree/ui-library/src/button/button';
import ResponseRateIcon from '@gumtree/ui-library/src/icon/svg/response_rate.svg';
import HandShakeIcon from '@gumtree/ui-library/src/icon/svg/handshake.svg';
import { ExperimentNames } from '@gumtree/shared/src/model/experiment';
import type { ShellState, ShellUseDispatch } from '../../../../reducers/common';
import { trackAndNavigate } from '../../../../common/common-actions';

import {
    LoginToolTipWrapper,
    LoginToolTip,
    ContentWrapper,
    Title,
    Description,
    CloseButton,
    ButtonWrapper,
    CTAButton,
    StyledList,
    StyledListItemDescription,
    StyledIcon,
} from './style';

interface Props extends ButtonProps {
    onClose?: MouseEventHandler;
    useSelector?: typeof useSelectorHook;
}

const NavLoginToolTip = ({ onClose, useSelector = useSelectorHook }: Props) => {
    const dispatch = useDispatch() as ShellUseDispatch;
    const { sellerUrl, buyerUrl } = useSelector(({ baseConfig }: ShellState) => baseConfig);
    const experiments = useSelector(({ experiments }: ShellState) => experiments);
    const toolTipRef = useRef<HTMLDivElement>(null!);
    const inExperimentGTC1815 = experiments?.[ExperimentNames.REGISTER_LOGIN_BFF] === 'B';

    useEffect(() => {
        const hideLoginToolTipHandler = (event) => {
            const tagName = event?.target?.tagName;
            const isToolTipElement = toolTipRef?.current?.contains(event?.target);
            const isInteractiveTag =
                tagName === 'BUTTON' ||
                tagName === 'A' ||
                tagName === 'INPUT' ||
                event?.target?.closest('a') ||
                event?.target?.closest('button') ||
                event?.target?.closest('input');
            if (!isToolTipElement && isInteractiveTag) {
                onClose?.(event);
                document.body.removeEventListener('click', hideLoginToolTipHandler);
            }
        };

        document.body.addEventListener('click', hideLoginToolTipHandler);

        return () => {
            document.body.removeEventListener('click', hideLoginToolTipHandler);
        };
    }, []);

    return (
        <LoginToolTipWrapper>
            <LoginToolTip ref={toolTipRef}>
                <CloseButton
                    icon={<Icon type="close-alt" />}
                    onClick={onClose}
                    aria-label="Close"
                />
                <ContentWrapper>
                    <Title>Welcome to Gumtree.</Title>
                    <Description>Sign in or Register to:</Description>
                    <StyledList>
                        <li>
                            <ResponseRateIcon />
                            <StyledListItemDescription>
                                Send and receive messages
                            </StyledListItemDescription>
                        </li>
                        <li>
                            <StyledIcon type="camera-add" />
                            <StyledListItemDescription>
                                Post and manage your ads
                            </StyledListItemDescription>
                        </li>
                        <li>
                            <HandShakeIcon />
                            <StyledListItemDescription>Rate other users</StyledListItemDescription>
                        </li>
                        <li>
                            <StyledIcon type="full-heart" />
                            <StyledListItemDescription>
                                Favourite ads to check them out later
                            </StyledListItemDescription>
                        </li>
                        <li>
                            <StyledIcon type="bell-tick" />
                            <StyledListItemDescription>
                                Set alerts for your searches and never miss a new ad in your area
                            </StyledListItemDescription>
                        </li>
                    </StyledList>
                </ContentWrapper>
                <ButtonWrapper>
                    <CTAButton
                        display="primary"
                        label="Register"
                        onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            dispatch(
                                trackAndNavigate('SignUpPromptRegisterSelect', inExperimentGTC1815 ? `${buyerUrl}/create-account` : `${sellerUrl}/create-account`)
                            );
                        }}
                        href={inExperimentGTC1815 ? `${buyerUrl}/create-account` : `${sellerUrl}/create-account`}
                        fullWidth
                    />
                    <CTAButton
                        display="secondary"
                        label="Login"
                        onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            dispatch(
                                trackAndNavigate('SignUpPromptLoginSelect', inExperimentGTC1815 ? `${buyerUrl}/login` : `${sellerUrl}/login`)
                            );
                        }}
                        href={inExperimentGTC1815 ? `${buyerUrl}/login` : `${sellerUrl}/login`}
                        fullWidth
                    />
                </ButtonWrapper>
            </LoginToolTip>
        </LoginToolTipWrapper>
    );
};

export default NavLoginToolTip;
