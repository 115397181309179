import { useState } from 'react';
import { CredentialResponse } from '@react-oauth/google';
import { trackGA4Event, trackGA4Async } from '@gumtree/shared/src/util/ga4-shared';
import jwtDecode from 'jwt-decode';
import { LOGIN_APP_DISPLAY_NAME, LOGIN_PLATFORM } from '../constants';
import { SubmitSocialLoginFunction } from '../types';
import { getErrorMsg } from '../utils/social-errors';

const useGoogleSignIn = (submitLogin: SubmitSocialLoginFunction, type: 'register' | 'login') => {
    const [errorMessage, setErrorMessage] = useState('');

    const handleGoogleSuccessResponse = async ({ credential }: CredentialResponse) => {
        if (!credential) {
            return;
        }

        const { email } = jwtDecode<{ email: string }>(credential);

        await trackGA4Async(type === 'login' ? {
            event: 'user_login',
            formName: 'user login',
            formStep: 'user login',
            formValidation: 'success',
            userAccountType: 'google',
        } : {
            event: 'user_registration',
            formName: 'user registration',
            formStep: 'user registration',
            formValidation: 'success',
            userAccountType: 'google',
        });

        submitLogin({
            username: email,
            password: credential,
            loginPlatform: LOGIN_PLATFORM.GOOGLE,
        });
    };

    const handleGoogleErrorResponse = () => {
        const error = getErrorMsg({ appDisplayName: LOGIN_APP_DISPLAY_NAME.GOOGLE });

        trackGA4Event(type === 'login' ? {
            event: 'user_login',
            formName: 'user login',
            formStep: 'user login',
            formValidation: 'failure',
            formError: error,
            userAccountType: 'google',
        } : {
            event: 'user_registration',
            formName: 'user registration',
            formStep: 'user registration',
            formValidation: 'failure',
            formError: error,
            userAccountType: 'google',
        });

        setErrorMessage(error);
    };

    return {
        onSuccess: handleGoogleSuccessResponse,
        onError: handleGoogleErrorResponse,
        errorMessage,
    };
};

export default useGoogleSignIn;
