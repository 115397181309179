// eslint-disable-next-line import/no-extraneous-dependencies
import isEqual from 'lodash/isEqual';

import {
    getArray,
    removeByKey,
    setValue,
} from '@gumtree/ui-library/src/utils/local-storage-service';

export type NamedObject = {
    name: string;
    [key: string]: any;
};

export const saveByKey = (key: string, data: NamedObject, max?: number) => {
    // Prepend the new item to the start of the array
    const newArray = [
        data,
        ...(getArray(key) as NamedObject[]).filter((item) => !isEqual(item, data)),
    ];

    // If the length of the new array exceeds the max limit,
    // keep only the first 'max' items, effectively removing the last one
    if (max !== undefined && newArray.length > max) {
        setValue(key, newArray.slice(0, max));
    } else {
        setValue(key, newArray);
    }
};

export const loadByKey = (key: string, limit: number): NamedObject[] =>
    (getArray(key) as NamedObject[]).slice(0, limit);

export const clearAllByKey = (key: string) => {
    removeByKey(key);
};

export const removeSingleByKey = (key: string, name: string) => {
    const recentSearches = getArray(key) as NamedObject[];

    if (!recentSearches.length) {
        return;
    }

    setValue(
        key,
        recentSearches.filter((_) => _?.name !== name)
    );
};
