import { connect } from 'react-redux';
import Header from './header';

const mapState = ({ baseConfig: { location, device }, searchBar, experiments }) => ({
    location,
    experiments,
    device: device?.type,
    isSearchBarOpen: searchBar.isOpen,
});

export default connect(mapState)(Header);
