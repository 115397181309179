import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import type { PagesState } from '@gumtree/shell/src/load-view';
import { AppViewDevices } from '@gumtree/shared/src/constants/app-view';

/**
 * - On mount prevent scrolling of the body.
 * - On unmount permit scrolling of the body.
 */
export default function ScrollStopper(props: { dialogRoot?: React.RefObject<HTMLDivElement> }) {
    const androidWebView = useSelector(
        (state: PagesState) => state.page.appViewDevice === AppViewDevices.ANDROID
    );

    useEffect(() => {
        if (!androidWebView) {
            const offset = window.scrollY;
            document.body.style.position = 'fixed';
            document.body.style.top = `-${offset}px`;
            document.body.style.width = '100%';

            return () => {
                const scrollY = document.body.style.top;
                document.body.style.position = '';
                document.body.style.top = '';
                document.body.style.width = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
            };
        }

        // Android WebView hangs if set body.position as 'fixed'
        const dialogRoot = props.dialogRoot?.current;
        const overlay = dialogRoot?.querySelector<HTMLDivElement>('.overlay');
        const dialog = dialogRoot?.querySelector<HTMLDivElement>('.dialog');
        if (!dialogRoot || !overlay || !dialog) {
            return;
        }

        const handleEvent = (e: Event) => {
            const target = e.target as HTMLElement;
            // Prevent scroll on overlay
            if (target.classList.contains('dialog-container')) {
                e.preventDefault();
            }
            // Prevent scroll unless target is scrollable inside Dialog
            if (
                !getAncestorsUntil(target, overlay).some((el) => {
                    const { height, width } = el.getBoundingClientRect();
                    return el.scrollHeight > height + 2 || el.scrollWidth > width + 2;
                })
            ) {
                e.preventDefault();
            }
        };

        overlay.addEventListener('scroll', handleEvent, false);
        overlay.addEventListener('mousewheel', handleEvent, false);
        overlay.addEventListener('touchmove', handleEvent, false);

        dialog.style.overscrollBehavior = 'none';

        return () => {
            overlay.removeEventListener('scroll', handleEvent, false);
            overlay.removeEventListener('mousewheel', handleEvent, false);
            overlay.removeEventListener('touchmove', handleEvent, false);
            dialog.style.overscrollBehavior = 'auto';
        };
    }, [props.dialogRoot?.current]);

    return null;
}

function getAncestorsUntil(el: HTMLElement, ancestor: HTMLElement) {
    const ancestors = [el];
    let parent: HTMLElement | null; // eslint-disable-next-line no-cond-assign
    while ((parent = el.parentElement) !== null && ancestors.push(parent) && parent !== ancestor)
        el = parent;
    return ancestors;
}
