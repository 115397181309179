import { GrowthBook } from '@growthbook/growthbook-react';
import { FeatureApiResponse } from '@growthbook/growthbook';
import { ExperimentOverride } from '@gumtree/web-bff/src/render/util/experiment-override';
import { trackGA4AfterConsent } from '@gumtree/shared/src/util/ga4-shared';
import logger from '../logger';

let growthbook;

const initGrowthbook = (
    growthbookExperimentData: FeatureApiResponse,
    appViewDevice?: 'iOS' | 'Android' | null,
    gbUserPseudoId?: string,
    includeTrackingCallback?: boolean
) => {
    if (includeTrackingCallback) {
        growthbook = new GrowthBook({
            trackingCallback: async (experiment, result) => {
                await trackGA4AfterConsent({
                    event: 'experiment_viewed',
                    experimentId: experiment.key,
                    variationId: result.key,
                });
            },
        });
    } else {
        growthbook = new GrowthBook();
    }

    growthbook.init({
        payload: growthbookExperimentData,
    });

    growthbook.setAttributes({
        isAppView: !!appViewDevice,
        ...(gbUserPseudoId && { gbUserPseudoId }),
    });

    return growthbook;
};

const initForcedFeatures = (
    growthbook: GrowthBook,
    experimentOverrides: Array<ExperimentOverride>
) => {
    if (experimentOverrides && experimentOverrides.length > 0) {
        const overriddenFeatures = new Map();

        experimentOverrides.forEach((extractedExperiment) => {
            if (extractedExperiment) {
                overriddenFeatures.set(extractedExperiment.key, extractedExperiment.value);
            }
        });

        growthbook.setForcedFeatures(overriddenFeatures);
    }
};

const getGrowthbook = () => {
    if (!growthbook) {
        logger.error('Growthbook has not been initialized. Please call initGrowthbook first.');
    }
    return growthbook;
};

export { initGrowthbook, initForcedFeatures, getGrowthbook };
