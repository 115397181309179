import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { trackV2 } from '@gumtree/shared/src/util/track-ga-event';
import { Icon } from '@gumtree/ui-library';
import { POLLING_INTERVAL_MS } from '@gumtree/shared/src/constants/message-centre';
import { ExperimentNames, ExperimentVariant } from '@gumtree/shared/src/model/experiment';
import { isInExperiment } from '@gumtree/shared/src/experiments/experiments';
import type { ShellState } from '../../../reducers/common';
import { MessagesButton, StyledCountBullet, StyledWrapper } from './style';
import { fetchUnreadMessagesCount } from '../../../reducers/common/unread-messages';
import { ShellUseDispatch } from '../../../reducers/common';
import logger from '../../../logger';

export default function NavBarMessagesButton() {
    const dispatch = useDispatch() as ShellUseDispatch;
    const {
        baseConfig: { bffUrl, pageType },
        unreadMessagesCount,
        experiments
    } = useSelector(selectState);

    const showUnreadCount = isInExperiment(experiments, ExperimentNames.UNREAD_FEATURE_FLAG, ExperimentVariant.VARIANT_B);

    useEffect(() => {
        if (!showUnreadCount || pageType === 'MyMessages') {
            return;
        }

        let intervalId = 0;
        function intervalCallback() {
            // Dispatch the action and handle its result
            dispatch(fetchUnreadMessagesCount())
                .then((shouldPoll) => {
                    if (!shouldPoll) {
                        clearInterval(intervalId);
                    }
                })
                .catch((error) => {
                    logger.error('Error fetching unread messages count:', error);
                });
        }
        intervalId = window.setInterval(intervalCallback, POLLING_INTERVAL_MS);
        intervalCallback();

        return () => clearInterval(intervalId);
    }, [pageType]);

    const unreadMessagesCountToDisplay = Math.min(unreadMessagesCount, 99);

    return (
        <StyledWrapper>
            <MessagesButton
                {...qaAttribute('my-gumtree-manage-messages')}
                data-testid="my-gumtree-manage-messages"
                aria-label="Messages"
                className="messages-button"
                dark
                label="Messages"
                icon={<Icon type="envelope" />}
                href={`${bffUrl}/manage/messages`}
                bottomLabel
                onClick={() => trackV2('manageMessagesHeader')}
            />
            {(showUnreadCount && Boolean(unreadMessagesCount)) && (
                <StyledCountBullet>{unreadMessagesCountToDisplay}</StyledCountBullet>
            )}
        </StyledWrapper>
    );
}

function selectState({ baseConfig, unreadMessagesCount, experiments }: ShellState) {
    return {
        baseConfig,
        unreadMessagesCount,
        experiments
    };
}
