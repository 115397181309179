import { isMobileSkinsEnabled } from '@gumtree/shared/src/mobile-skins/is-mobile-skins-enabled';
import { validateOrigin } from '@gumtree/shared/src/util/message-helpers';

const mobileSkinsAnimation = (device, pageType, l1Category, l2Category, experiments, window) => {
    if (!isMobileSkinsEnabled(device, pageType, l1Category, l2Category, experiments)) {
        return;
    } else {
        window.addEventListener('message', (event) => {
            if (!validateOrigin(window, event.origin)) {
                return;
            }
            if (typeof event.data === 'string' && event.data.includes('MOBILE_SKINS_LOADED')) {
                const watchPixel = document.createElement('DIV') as HTMLElement;
                watchPixel.classList.add('mobile-skins-watch-pixel');
                watchPixel.style.cssText = `
                    position: absolute;
                    top: 10px;
                    width: 100%;
                    height: 1px;
                `;
                document.body.appendChild(watchPixel);

                const observer = new IntersectionObserver((entries) => {
                    const header = document.querySelector('.header') as HTMLElement;
                    if (entries[0].boundingClientRect.y < 0) {
                        header.style.transform = 'translateY(-100%)';
                    } else {
                        header.style.transform = 'translateY(0)';
                    }
                });
                observer.observe(watchPixel);
            }
        });
    }
};

export default mobileSkinsAnimation;
