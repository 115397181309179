/* eslint-disable react/require-default-props */
import React from 'react';
import { css } from '@emotion/react';

import { Grid } from '@gumtree/ui-library';
import {
    fontSizes,
    fontWeights,
    colorVariables,
    mediaQuery,
    breakpoints,
} from '@gumtree/ui-library/src/base/theme';
import SubNavPanelItem, { NavRibbonSectionTitle, onClickFunction } from './sub-nav-panel-section-item';

const GridStyle = css`
    margin-bottom: 25px;

    ${mediaQuery.from(breakpoints.medium)} {
        margin-bottom: 0;
    }
`;

const PanelTitleStyle = css`
    font-size: ${fontSizes.base};
    font-weight: ${fontWeights.bold};
    margin-bottom: 20px;
    color: ${colorVariables.textPrimary};
`;

const NUMBER_OF_ITEM_COLUMNS = 4;

export default function SubNavPanelSection({
    title,
    items = [],
    numberOfItemColumns = NUMBER_OF_ITEM_COLUMNS,
    onItemClick,
    col,
    colS,
    colM,
    colL,
    colXl,
    colXxl,
    ...props
}: Props) {
    const maxItemsInColumn = Math.ceil(items.length / numberOfItemColumns);
    const columnOfItems: Array<any> = [];

    items.forEach((item, i) => {
        if (i % maxItemsInColumn === 0) {
            columnOfItems.push([item]);
        } else {
            columnOfItems[columnOfItems.length - 1].push(item);
        }
    });

    return (
        <Grid
            css={GridStyle}
            item
            col={col}
            colS={colS}
            colM={colM}
            colL={colL}
            colXl={colXl}
            colXxl={colXxl}
            {...props}
        >
            <Grid container>
                {title && (
                    <Grid item col={12}>
                        <div css={PanelTitleStyle}>{title}</div>
                    </Grid>
                )}
                {columnOfItems.map((colummn, i) => {
                    return (
                        <Grid
                            item
                            key={`sub-nav-panel-column-${i}`}
                            col={12}
                            colM={6}
                            colL={12 / numberOfItemColumns}
                        >
                            <ul data-q="category-column-panel">
                                {colummn.map((item, i) => (
                                    <SubNavPanelItem
                                        key={`sub-nav-panel-column-item-${i}`}
                                        href={item.path}
                                        label={item.name}
                                        onClick={onItemClick}
                                        sectionTitle={title}
                                    />
                                ))}
                            </ul>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
}

interface Props {
    title: NavRibbonSectionTitle;
    items: Array<any>;
    numberOfItemColumns?: number;
    onItemClick?: onClickFunction;
    col: number;
    colS?: number;
    colM?: number;
    colL?: number;
    colXl?: number;
    colXxl?: number;
}
