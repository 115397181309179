import { DfpServerConfig, GoogleTag } from '@gumtree/shared/src/types/advertising/dfp';
import {
    Device,
    L1Category,
    L2Category,
    PageType,
    Experiments,
} from '@gumtree/shared/src/types/client-data';
import { isClientDataExist } from '@gumtree/shared/src/util/client-data';
import { convertExperimentsToStringArray } from '@gumtree/shared/src/util/experiments-service';
import { WindowWithGoogleTag, WindowWithAdvertising } from '@gumtree/shared/src/types/window';
import { isMobileSkinsEnabled } from '@gumtree/shared/src/mobile-skins/is-mobile-skins-enabled';
import { getCurrentBreakpoint } from '@gumtree/shared/src/util/breakpoints-service';

import { getConsentData } from '../gdpr/util';

import getDfpSlotsConfig from './ad-config';

import DisplayAdSlot from './display-ad-slot';
import getInskinKeyValue from './get-inskin-key-value';
import addEventListenerForHouseRefresh from './house-refresh-event-listener';
import { getLazyLoadConfig, LazyLoadConfigType } from './lazy-load';
import { initialiseTcfACString } from '../gdpr/onetrust-add-additional-consent-string';
import { PageConfig } from './ad-config/build-page-config';
import getIsBffValue from './is-bff-key-value';

type Callback = () => void;

const prioritizedDfpOnlySlotIds = (experiments: Experiments) => {
    const slots = [
        'premiumNative1',
        'premiumNative2',
        'top2',
        'overlayVideo',
        'top3',
        'leaderboard',
    ];
    if (experiments['GTALL-23990'] === 'B') {
        return [...slots, 'pixel'];
    } else {
        return slots;
    }
};
const skippingInitialLoadSlotIds = ['overlayVideo'];
let isInitialisationComplete = false;
const queuedCommands: Callback[] = [];

const isConfigValid = (googletag: GoogleTag, config: DfpServerConfig, slots: object) => {
    return Boolean(googletag && config && slots && Object.keys(slots).length);
};

const configurePageOptions = (
    googletag: GoogleTag,
    config: DfpServerConfig,
    pageType: PageType,
    experiments: Experiments,
    hashedUserIdFromDataLayer: string | undefined,
    deviceType: Device,
    l1Category: L1Category
) => {
    googletag.cmd.push(() => {
        const ppid = config.ppid || hashedUserIdFromDataLayer;
        const { isGoogleAdsLazyLoadEnabled, fetchMargin, renderMargin }: LazyLoadConfigType =
            getLazyLoadConfig(pageType, experiments, deviceType, l1Category);

        if (config.targeting) {
            Object.entries(config.targeting).forEach(([key, value]) => {
                key !== undefined && (key = key.toString());
                value !== undefined && (value = value.toString());
                googletag.pubads().setTargeting(key, value as string);
            });
        }

        if (pageType === 'ResultsSearch' || pageType === 'ResultsBrowse') {
            googletag.pubads().setTargeting(
                'is_bff',
                getIsBffValue({
                    pageType,
                    isOnBff: isClientDataExist(window),
                    isGridView: experiments['GTF-1068'] === 'B' || experiments['GTF-1827'] === 'B',
                })
            );
        }

        if (ppid && 0 !== ppid.length) {
            googletag.pubads().setPublisherProvidedId(ppid);
        }
        googletag.pubads().disableInitialLoad();
        googletag.pubads().setCentering(true);
        googletag.pubads().enableSingleRequest();
        if (isGoogleAdsLazyLoadEnabled) {
            googletag.pubads().enableLazyLoad({
                fetchMarginPercent: fetchMargin,
                renderMarginPercent: renderMargin,
                mobileScaling: 2.0,
            });
        }
        googletag.enableServices();
    });
};

export const loadAds = (
    slotInstances: DisplayAdSlot[],
    googletag: GoogleTag,
    pageType: PageType,
    w: WindowWithGoogleTag
) => {
    const command = async () => {
        const screenWidth = w.innerWidth;
        getInskinKeyValue(screenWidth, pageType).forEach(({ key, value }) =>
            googletag.pubads().setTargeting(key, value)
        );

        const { coarseConsent } = await getConsentData(window);

        if (coarseConsent) {
            googletag.pubads().setTargeting('adconsent', coarseConsent);
        }

        googletag.pubads().refresh(slotInstances.map(({ adSlot }) => adSlot));
    };
    if (isInitialisationComplete) {
        googletag.cmd.push(command);
    } else {
        queuedCommands.push(command);
    }
};

const loadNonPrebidAdSlots = (
    slotInstances: DisplayAdSlot[],
    googletag: GoogleTag,
    pageType: PageType,
    experiments: Experiments,
    w: WindowWithGoogleTag
) => {
    loadAds(
        slotInstances.filter(
            (instance) =>
                prioritizedDfpOnlySlotIds(experiments).includes(instance.spec.slotId) &&
                !skippingInitialLoadSlotIds.includes(instance.spec.slotId)
        ),
        googletag,
        pageType,
        w
    );
};

const refreshAds =
    (
        slotInstances: DisplayAdSlot[],
        googletag: GoogleTag,
        pageType: PageType,
        deviceType: Device,
        l1Category: L1Category,
        w: WindowWithGoogleTag & WindowWithAdvertising
    ) =>
    () => {
        if (w.advertising?.display?.isHouseRefreshOff !== true) {
            loadAds(
                slotInstances.filter((i) => i.isRefreshable(deviceType, l1Category, pageType, w)),
                googletag,
                pageType,
                w
            );
        }
    };

const initDisplayAds = async (
    googletag: GoogleTag,
    window: WindowWithGoogleTag & WindowWithAdvertising,
    config: DfpServerConfig | undefined,
    resultSize = 0,
    l1Category: L1Category,
    l2Category: L2Category,
    pageType: PageType,
    device: Device,
    experiments: Experiments,
    hashedUserIdFromDataLayer: string | undefined
) => {
    if (!config) {
        return;
    }

    const slots: PageConfig = getDfpSlotsConfig({
        pageType,
        resultSize,
        device,
        l1Category: l1Category || '',
        l2Category,
        abTests: convertExperimentsToStringArray(experiments),
        isClientDataExist: isClientDataExist(window),
        isMobileSkinsEnabled: isMobileSkinsEnabled(
            device,
            pageType,
            l1Category,
            l2Category,
            experiments
        ),
    });

    if (isConfigValid(googletag, config, slots)) {
        const slotsToFetch = slots.filter(
            (config) =>
                config.shouldRenderEvenNotOnDom ||
                Boolean(window.document.getElementById(config.slotId))
        );

        slotsToFetch.forEach((slot) => {
            // some ad placeholders are "display: none" initially and needs to be uncovered
            // except those marked startWithCollapsedSlot
            const el = window.document.getElementById(slot.slotId);
            const currentScreenTShirtSize = getCurrentBreakpoint(window)?.tshirt;
            const isPlaceholderStartWithCollapsedSlot =
                slot.startWithCollapsedSlot &&
                slot.startWithCollapsedSlot.find(
                    (_) =>
                        _.pageType === pageType &&
                        currentScreenTShirtSize &&
                        _.screenSizes.includes(currentScreenTShirtSize)
                );

            if (el && !isPlaceholderStartWithCollapsedSlot) {
                el.style.removeProperty('display');
            }
        });

        const slotInstances: DisplayAdSlot[] = slotsToFetch.map(
            (slotConfig) => new DisplayAdSlot(pageType, slotConfig, googletag, window)
        );

        const prebidOrAllInstances = slotInstances.filter(
            (instance) => !prioritizedDfpOnlySlotIds(experiments).includes(instance.spec.slotId)
        );

        addEventListenerForHouseRefresh(window);

        // event listeners must be defined before the events trigger in Prebid
        window.document.addEventListener('GUM.ADS.DISPLAY.LOAD', () => {
            loadAds(prebidOrAllInstances, googletag, pageType, window);
        });

        window.document.addEventListener(
            'GUM.ADS.DISPLAY.REFRESH',
            refreshAds(slotInstances, googletag, pageType, device, l1Category, window)
        );

        configurePageOptions(
            googletag,
            config,
            pageType,
            experiments,
            hashedUserIdFromDataLayer,
            device,
            l1Category
        );

        slotInstances.forEach((slot) => slot.callGoogleDisplay(window));

        if (experiments['GTALL-23990'] === 'B') {
            loadNonPrebidAdSlots(slotInstances, googletag, pageType, experiments, window);
        }

        await initialiseTcfACString(window);

        isInitialisationComplete = true;

        queuedCommands.forEach((command) => {
            googletag.cmd.push(command);
        });

        if (experiments['GTALL-23990'] !== 'B') {
            loadNonPrebidAdSlots(slotInstances, googletag, pageType, experiments, window);
        }
    }
};

export default initDisplayAds;
