/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useState as useStateHook, useRef } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector as useSelectorHook } from 'react-redux';

import type { DeviceTypes } from '@gumtree/shared/src/types/client-data';
import { getCurrentBreakpoint } from '@gumtree/shared/src/util/breakpoints-service';
import { trackGA4Async, trackGA4Event } from '@gumtree/shared/src/util/ga4-shared';
import { getDomainFromUrl } from '@gumtree/shared/src/util/url-service';
import { zIndexes, colors } from '@gumtree/ui-library/src/base/theme';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';

import type { ShellState, ShellUseDispatch } from '../reducers/common';

import { expandPetsCategory, redirectCarCategoryToLandingPage } from './reducer';
import { setAppBannerCookie } from '../app-banner-takeover/app-banner-takeover-logic';

import NavItemList from './category-nav-ribbon-item-list';
import SubNavPanel from './sub-nav-panel';
import { trackAndNavigate, trackV2 } from '../common/common-actions';

const Overlay = styled.div`
    z-index: ${zIndexes.low - 1};
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${colors.black};
    opacity: 0.5;
`;

const NavItemListContainer = styled.div<{ isActive: boolean }>`
    position: relative;
    background-color: ${colors.barkDark};
    background-color: ${colors.white};
    border-bottom: 1px solid ${colors.bark20};

    ${(p) =>
        p.isActive &&
        `
            z-index: ${zIndexes.high};
            background-color: ${colors.branch10};
            border-bottom: initial;
        `}
`;

const FIRST_TIME_PANEL_EXPAND_DELAY = 200;

interface Props {
    onMouseLeave?: EventListener;
    useState?: typeof useStateHook;
    useSelector?: typeof useSelectorHook;
    className?: string;
}

const NavigationRibbon = ({
    onMouseLeave,
    useState = useStateHook,
    useSelector = useSelectorHook,
    ...props
}: Props) => {
    const dispatch = useDispatch() as ShellUseDispatch;

    const categoryNavState = useSelector(({ categoryNav }: ShellState) => categoryNav);

    const [isShowHoverUnderline, setIsShowHoverUnderline] = useState<boolean>(false);
    const [activeCategory, setActiveCategory] = useState<string | null>(null);
    const [activeNavItemIndex, setActiveNavItemIndex] = useState<number | null>(null);
    const [delayedPanelOpenId, setDelayedPanelOpenId] = useState<number>();
    const [firstHoverTime, setFirstHoverTime] = useState<number | null>(null);

    const { domain, device } = useSelector(({ baseConfig }: ShellState) => baseConfig);
    const { items = [] } = categoryNavState;

    const navItems = useMemo(
        () => expandPetsCategory(redirectCarCategoryToLandingPage(items)),
        [items]
    );
    const navItemListContainerRef = useRef(null!);

    useEffect(() => {
        setAppBannerCookie(domain, device?.os === 'IOS');
    }, []);

    if (!categoryNavState.display) {
        return <></>;
    }

    const openSubNavPanelNow = (seoName) => {
        setActiveCategory(seoName);
        setFirstHoverTime(null);
        const eventLabel = navItems.find((ele) => ele.seoName === seoName)?.name;
        trackV2('NavL1CategoryHover', eventLabel);
        trackGA4Event<GA4.NavigationMenuExpanded>({
            event: 'navigation_menu_expanded',
            navigationCategory: eventLabel ?? seoName,
        });
    };

    const openSubNavPanel = (seoName) => {
        if (!activeCategory) {
            if (!firstHoverTime) {
                setFirstHoverTime(Date.now());
                setDelayedPanelOpenId(
                    setTimeout(() => {
                        openSubNavPanelNow(seoName);
                    }, FIRST_TIME_PANEL_EXPAND_DELAY) as unknown as number
                );
            } else {
                clearTimeout(delayedPanelOpenId);
                const timeStayedHovering = Date.now() - firstHoverTime;
                setDelayedPanelOpenId(
                    setTimeout(() => {
                        openSubNavPanelNow(seoName);
                    }, FIRST_TIME_PANEL_EXPAND_DELAY - timeStayedHovering) as unknown as number
                );
            }
        } else {
            openSubNavPanelNow(seoName);
        }
    };

    const closeSubNavPanel = () => {
        activeCategory &&
            trackGA4Event<GA4.NavigationMenuMinimised>({
                event: 'navigation_menu_minimised',
            });
        setActiveCategory(null);
        setFirstHoverTime(null);
    };

    const navItemMouseEnterHandler = (_e, { seoName, index }) => {
        if (
            // only expand for desktop device except touch devices, since this event is also triggerd at the same time by touch device tap
            ['desktop'].includes(device?.type as DeviceTypes) &&
            getCurrentBreakpoint()?.tshirt !== 'xs'
        ) {
            openSubNavPanel(seoName);
        }
        setActiveNavItemIndex(index);
        setIsShowHoverUnderline(true);
    };

    const navItemClickHandler = async (e, { seoName, path, name }) => {
        const nextActiveCategory = seoName;

        if (nextActiveCategory === activeCategory) {
            dispatch(trackAndNavigate('NavL1CategoryClick', path, name));
            await trackGA4Async<GA4.ClickNavigationMenu>({
                event: 'click_navigation_menu',
                linkDomain: getDomainFromUrl(path),
                linkText: name,
                linkURL: path,
                navigationCategory: seoName as any,
                navigationHeader: undefined,
                navigationHierarchy: 'category',
            });
            return;
        }

        e.preventDefault();
        openSubNavPanel(nextActiveCategory);
    };

    const cancelPreviousOpenSubNavPanel = () => clearTimeout(delayedPanelOpenId);

    const mouseLeaveHandler = (e) => {
        if (getCurrentBreakpoint()?.tshirt !== 'xs') {
            closeSubNavPanel();
            cancelPreviousOpenSubNavPanel();
        }
        setIsShowHoverUnderline(false);
        onMouseLeave && onMouseLeave(e);
        setActiveNavItemIndex(null);
    };

    const subNavPanelOnCloseHandler = () => {
        closeSubNavPanel();
        setIsShowHoverUnderline(false);
    };

    return (
        <>
            <div
                role="menu"
                tabIndex={0}
                onMouseLeave={mouseLeaveHandler}
                {...props}
                {...qaAttribute('category-nav-ribbon')}
            >
                <NavItemListContainer
                    ref={navItemListContainerRef}
                    isActive={activeCategory !== null}
                >
                    <NavItemList
                        items={navItems}
                        showHoverUnderline={isShowHoverUnderline}
                        onItemClick={navItemClickHandler}
                        onItemMouseEnter={navItemMouseEnterHandler}
                        activeItemIndex={activeNavItemIndex}
                    />
                </NavItemListContainer>
                {navItems.map((item) => (
                    <SubNavPanel
                        title={item.name}
                        seoName={item.seoName}
                        isActive={item.seoName === activeCategory}
                        key={`cat-nav-ribbon-panel-${item.seoName}`}
                        items={item.subitems}
                        onClose={subNavPanelOnCloseHandler}
                    />
                ))}
            </div>
            {activeCategory && <Overlay />}
        </>
    );
};

export default NavigationRibbon;
