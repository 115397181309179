import { colors } from '../theme';
import { GumtreeTheme } from './gumtree-theme.d';

const appViewColors = {
    white: '#FFF',

    green: '#5CF100',
    darkGreen: '#54DB00',
    darkerGreen: '#41AB00',

    linkGreen: '#028546',

    grey: '#E7EDEF',
    darkGrey: '#B4C7CC',

    textNavyBlue: '#0D495C',
    errorRed: '#D03E3E',
};

export const appViewTheme: GumtreeTheme = {
    name: 'appViewTheme',
    palette: {
        primary: {
            main: appViewColors.green,
            mainContrastText: appViewColors.textNavyBlue,
            dark: appViewColors.darkGreen,
            darkContrastText: appViewColors.textNavyBlue,
            darker: appViewColors.darkerGreen,
            darkerContrastText: appViewColors.textNavyBlue,
        },
        secondary: {
            main: appViewColors.white,
            mainContrastText: appViewColors.textNavyBlue,
            dark: appViewColors.grey,
            darkContrastText: appViewColors.textNavyBlue,
            darker: appViewColors.darkGrey,
            darkerContrastText: appViewColors.textNavyBlue,
        },
        iconBtn: {
            // Not in the scope yet so is inheriting from baseTheme
            icon: colors.berry,
            main: colors.white,
            border: colors.bark20,
            borderDark: colors.bark20,
            iconHover: colors.white,
            mainHover: colors.berry,
            mainHoverDark: colors.bark,
            borderHover: colors.bark20,
            ctaIcon: colors.berry,
        },
        danger: {
            // These aren't used by anything and will need changed when the time comes.
            main: colors.berry,
            mainContrastText: colors.white,
            dark: colors.berryDark,
            darkContrastText: colors.white,
            darker: colors.berryDark,
            darkerContrastText: colors.white,
        },
        disabled: {
            // Not in the scope yet so is inheriting from baseTheme
            main: colors.lightGrey,
            mainContrastText: `${colors.black}33`, // Originally it's rgba(0,0,0,0.2)
            border: colors.branch20,
        },
        error: {
            text: appViewColors.errorRed,
        },
        action: {
            active: appViewColors.linkGreen,
        },
        input: {
            icon: colors.bark,
        },
    },
};
