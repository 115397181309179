/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { Component, HTMLProps } from 'react';
import classnames from 'classnames';

import FormElement from './form-element';
import Button from '../button/button';
import Icon from '../icon/icon';
import { qaAttribute } from '../utils/qa-service';

import './input.scss';
import './form-element.scss';

class Input extends Component<Props & typeof Input.defaultProps, State> {
    static defaultProps = {
        defaultValue: undefined,
        inline: false,
        inputRef: (_) => {},
        onInput: () => {},
        type: 'text',
        hideClear: false,
    };

    state = {
        hasValue: !!this.props?.value,
        isFocused: false,
    };

    /** Initialised in `handleInputRef`. */
    private input!: HTMLInputElement;

    handleClear = () => {
        this.input.value = '';
        this.props.onClear?.();
        const event = new Event('input', { bubbles: true });
        this.input.dispatchEvent(event);
        this.input.focus();
    };

    handleInput = (event) => {
        this.setState({
            hasValue: !!this.input.value,
            isFocused: true,
        });
        const { onInput } = this.props;
        onInput(event);
    };

    handleInputRef = (element) => {
        this.input = element;
        const { inputRef } = this.props;
        inputRef(element);
    };

    getClassName = ({ type }) =>
        classnames('input', {
            [`input-${type}`]: type,
        });

    render() {
        const {
            id,
            type,
            name = id,
            inline,
            label,
            altLabel,
            hideClear,
            inputRef: _,
            errorMessages,
            errorAdditionalInfo,
            revealValueFunction,
            isPasswordVisible,
            labelClassName = '',
            onClear: ___,
            onFocus,
            onBlur,
            ...props
        } = this.props;
        const { isFocused } = this.state;

        return (
            <FormElement type="input" inline={inline}>
                {altLabel ? (
                    <label className={labelClassName} htmlFor={altLabel}>{label}</label>
                ) : (
                    label && <label htmlFor={label}>{label}</label>
                )}
                <input
                    {...props}
                    onFocus={(_) => {
                        this.setState({ isFocused: true });
                        onFocus && onFocus(_);
                    }}
                    onBlur={(_) => {
                        // using setTimeout here to make sure the clear input click event has had time to run
                        setTimeout(() => {
                            this.setState({ isFocused: false });
                        }, 300);
                        onBlur && onBlur(_);
                    }}
                    type={type}
                    className={this.getClassName({ type })}
                    id={id}
                    name={name}
                    onInput={this.handleInput}
                    ref={this.handleInputRef}
                    style={errorMessages?.length ? { border: '1px solid red' } : {}}
                    data-testid={`input-${id}`}
                />
                {revealValueFunction && (
                    <button
                        className="reveal-button-show"
                        onClick={(e) => {
                            e.preventDefault();
                            revealValueFunction();
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault();
                                revealValueFunction();
                            }
                        }}
                        type="button"
                        tabIndex={0}
                    >
                        {isPasswordVisible ? 'Hide' : 'Show'}
                    </button>
                )}
                {errorMessages?.length ? (
                    <span className="error-messages">
                        {errorMessages}
                        {errorAdditionalInfo ? (
                            <button className="error-additional-info" type="button" onClick={errorAdditionalInfo?.link}>
                                {errorAdditionalInfo.text}
                            </button>
                        ) : (
                            ''
                        )}
                    </span>
                ) : (
                    ''
                )}
                {!hideClear && isFocused && this.state.hasValue && (
                    <Button
                        aria-label="Clear input"
                        type="button"
                        className="input-clear"
                        icon={<Icon type="circ-error" size="smaller" />}
                        onClick={this.handleClear}
                        {...qaAttribute('clear-input-button')}
                    />
                )}
            </FormElement>
        );
    }
}

interface ErrorAdditionalInfo {
    text: string;
    link: () => void;
}

interface Props extends HTMLProps<HTMLInputElement> {
    labelClassName?: string;
    defaultValue?: string;
    value?: string;
    passwordStrength?: string;
    revealValueFunction?: () => void;
    hideClear?: boolean;
    id?: string;
    label?: string;
    altLabel?: string;
    inline?: boolean;
    inputRef?: (...args: any[]) => void;
    name?: string;
    onInput?: (...args: any[]) => void;
    onClear?: () => void;
    type?: string;
    /** Can be passed down from `Field` component. */
    errorMessages?: any;
    errorAdditionalInfo?: ErrorAdditionalInfo;
    isPasswordVisible?: boolean;
}

interface State {
    hasValue: boolean;
    isFocused: boolean;
}

export default Input;
