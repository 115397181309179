/* eslint-disable no-underscore-dangle */
import { hasAcceptedToAll } from '../gdpr/util';

const setPermutiveConsent = async (window) => {
    if (!window.__tcfapi) {
        return;
    }
    window.__tcfapi('addEventListener', 2, (tcData, success) => {
        const { eventStatus, vendor, purpose } = tcData;
        if (success && eventStatus !== 'cmpishown') {
            const acceptedToAll = hasAcceptedToAll(vendor.consents, purpose.consents);
            if (window.permutive) {
                if (acceptedToAll) {
                    window.permutive.consent({ opt_in: true, token: 'OK' });
                } else {
                    window.permutive.consent({ opt_in: false });
                }
            }
        }
    });
};

export default setPermutiveConsent;
