/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import UaParser from 'ua-parser-js';

import { Icon } from '@gumtree/ui-library';
import { ShellState } from '../reducers/common';

import './index.scss';

export const IEUpgradeBanner: React.FC = () => {
    const [isOpen, setIsOpen] = useState(true);
    const { request } = useSelector((state: ShellState) => state);
    const parser = new UaParser(request.headers['user-agent']);
    const browser = parser?.getBrowser();
    const isInternetExplorer = browser?.name === 'IE';
    return (
        <>
            {isOpen && isInternetExplorer && (
                <div className="notification-box" aria-expanded="true">
                    <div>
                        <Icon type="warning" />
                        Support for Internet Explorer on Gumtree will soon stop. Please use one of
                        our recommended browsers to continue to use Gumtree as usual.
                        <a
                            href="https://help.gumtree.com/s/technical-issues?cat=Troubleshooting&article=Fixing-Browser-Issues2"
                            rel="noopener noreferrer"
                        >
                            {' '}
                            More information{' '}
                        </a>
                    </div>
                    <button
                        className="notification-button-clear"
                        onClick={() => setIsOpen(false)}
                        type="button"
                        style={{ border: 'none', background: 'none' }}
                    >
                        <Icon type="clear" size="small" />
                    </button>
                </div>
            )}
        </>
    );
};

export default IEUpgradeBanner;
