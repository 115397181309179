import styled from "@emotion/styled";
import {
    StyledFacebookButton,
    StyledGoogleButtonWrapper,
} from "@gumtree/register/src/components/social-form/style";
import { Dialog } from "@gumtree/ui-library";
import {
    breakpoints,
    colorVariables,
    colors,
    fontSizes,
    fontWeights,
    gutterSizes,
    mediaQuery,
} from "@gumtree/ui-library/src/base/theme";

export const StyledDialog = styled(Dialog)`
    max-width: 450px;
    .dialog {
        padding: ${gutterSizes.xxxlarge} ${gutterSizes.xlarge} ${gutterSizes.xlarge}
            ${gutterSizes.xlarge};
        border-radius: ${gutterSizes.base};
        text-align: center;

        ${mediaQuery.until(breakpoints.small)} {
            position: absolute;
            bottom: 0;
            margin: 0;
            width: 100%;
            border-radius: ${gutterSizes.base} ${gutterSizes.base} 0 0;
        }

        ${mediaQuery.from(breakpoints.medium)} {
            min-width: 450px;
        }

        .dialog-close > .icon--clear {
            color: ${colors.darkGrey};
            font-size: ${fontSizes.small14};
        }
    }

    ${StyledFacebookButton} {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 400px;
        width: 100%;
        padding: ${gutterSizes.base} ${gutterSizes.medium};
        background: ${colors.white};
        border: 1px solid ${colors.blade};
        border-radius: 3px;
        font-size: ${fontSizes.base};
        font-weight: ${fontWeights.bold};
        color: ${colors.blade};
        cursor: pointer;
        height: 44px;
        transition: background-color 200ms linear;

        &:hover {
            background-color: #e7edef;
        }
    }

    ${StyledGoogleButtonWrapper} {
        min-width: 400px;

        ${mediaQuery.until(breakpoints.medium)} {
            width: 100%;
            min-width: auto;
        }

        div[aria-labelledby="button-label"] {
            border: 1px solid ${colors.blade};
            height: 44px;
            transition: background-color 200ms linear;

            &:hover {
                background-color: #e7edef;
                border: 1px solid ${colors.blade};
            }

            > div > div {
                width: 24px !important;
                height: 24px !important;
                min-width: 24px !important;
            }

            > div > span {
                font-size: ${fontSizes.base};
                font-weight: ${fontWeights.bold} !important;
                font-family: "Inter" !important;
                color: ${colors.blade};
            }
        }
    }
`;

export const ModalTitle = styled.h2`
    font-weight: ${fontWeights.bold};
    font-size: ${fontSizes.pgLarge};
    color: ${colorVariables.textPrimary};
    margin: 0;

    ${mediaQuery.until(breakpoints.medium)} {
        font-size: ${fontSizes.xlarge};
    }
`;

export const ModalLogin = styled.p`
    font-size: ${fontSizes.base};
    margin-top: ${gutterSizes.base};
    margin-bottom: ${gutterSizes.xlarge};

    button {
        font-weight: ${fontWeights.bolder};
        border: none;
        background: none;
        font-size: ${fontSizes.base};
        color: ${colorVariables.textLink};
        padding: 0;
        text-decoration: underline;
        cursor: pointer;
    }
`;

export const EmailButton = styled.button`
    width: 100%;
    height: 44px;
    border: 1px solid ${colors.blade};
    background: ${colors.white};
    border-radius: 3px;
    font-weight: ${fontWeights.bold};
    color: ${colors.blade};
    margin-top: ${gutterSizes.large};
    transition: background-color 200ms linear;
    cursor: pointer;

    ${mediaQuery.until(breakpoints.medium)} {
        width: 100%;
    }

    &:hover {
        background-color: #e7edef;
    }
`;
