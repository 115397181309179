import { Device, L1Category, PageType, Experiments } from '@gumtree/shared/src/types/client-data';
import { resultsPages, vipPages } from '../ad-config/build-slot-config';

export type LazyLoadConfigType = {
    isGoogleAdsLazyLoadEnabled: boolean;
    fetchMargin: number;
    renderMargin: number;
};

const lazyLoadConfig = (
    pageType: PageType,
    experiments: Experiments,
    deviceType: Device,
    l1Category: L1Category | undefined
): LazyLoadConfigType => {
    const isSRP = resultsPages.includes(pageType);
    const isVIP = vipPages.includes(pageType);
    const experiment20693 = experiments['GTALL-20693'];
    const experiment24811 = experiments['GTALL-24811'];

    let isEnabled = false;
    let fetchMargin = -1;
    let renderMargin = -1;

    if (isSRP && deviceType === 'desktop' && l1Category === 'cars-vans-motorbikes') {
        isEnabled = true;
        renderMargin = 100;
    } else if (
        isSRP &&
        deviceType === 'mobile' &&
        l1Category === 'cars-vans-motorbikes' &&
        ['B', 'C', 'D'].includes(experiment24811)
    ) {
        const renderMargins = {
            B: 100,
            C: 200,
            D: 300,
        };
        isEnabled = true;
        renderMargin = renderMargins[experiment24811];
    } else if (isVIP && ['B', 'C', 'D', 'E'].includes(experiment20693)) {
        const renderMargins = {
            B: 200,
            C: 100,
            D: 200,
            E: 400,
            F: 200,
        };
        isEnabled = true;
        fetchMargin = ['B'].includes(experiment20693) ? 500 : -1;
        renderMargin = renderMargins[experiment20693];
    }

    return {
        isGoogleAdsLazyLoadEnabled: isEnabled,
        fetchMargin,
        renderMargin,
    };
};

export default lazyLoadConfig;
