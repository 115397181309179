import { getCookie } from './browser-service';

export const PREFIX = 'gt_userPref=';

export const encodeValue = (value: string | undefined): string | undefined => {
    if (value === undefined) {
        return undefined;
    }
    try {
        return btoa(value);
    } catch (e) {
        return undefined;
    }
};

export const decodeValue = (value: string | undefined): string | undefined => {
    if (value === undefined) {
        return undefined;
    }
    try {
        return atob(value);
    } catch (e) {
        return undefined;
    }
};

export const addValueToUserPref = (
    originalCookie: string | undefined,
    key: string,
    value: string | undefined
): string | undefined => {
    const newValueBase64Encoded = encodeValue(value);

    if (!newValueBase64Encoded) {
        return originalCookie;
    }

    if (!originalCookie) {
        return `${PREFIX}${key}:${newValueBase64Encoded}`;
    }

    const cookies: (string | undefined)[] = originalCookie.split('; ');
    const userPrefCookie = cookies.find((cookie) => cookie?.startsWith(PREFIX));

    if (!userPrefCookie) {
        // No existing userPref cookie, add a new one
        return [...cookies, `${PREFIX}${key}:${newValueBase64Encoded}`].join('; ');
    }

    const userPrefParts: string[] = userPrefCookie.substring(PREFIX.length).split('|');

    const isPartExist = userPrefParts.some((part) => part.startsWith(`${key}:`));

    const updatedUserPrefParts = isPartExist
        ? userPrefParts.map((part) =>
              part.startsWith(`${key}:`) ? `${key}:${newValueBase64Encoded}` : part
          )
        : [...userPrefParts, `${key}:${newValueBase64Encoded}`];

    // Reassemble the userPref cookie and update it in the cookies array
    const updatedUserPrefCookie = `${PREFIX}${updatedUserPrefParts.join('|')}`;

    return cookies
        .map((cookie) => (cookie?.startsWith(PREFIX) ? updatedUserPrefCookie : cookie))
        .join('; ');
};

export const getValueFromUserPref = (key: string): string | undefined => {
    const originalCookie = getCookie();

    if (!originalCookie) {
        return undefined;
    }

    const cookies: string[] = originalCookie.split('; ');
    const userPrefCookie = cookies.find((cookie) => cookie?.startsWith(PREFIX));

    if (!userPrefCookie) {
        return undefined;
    }

    const userPrefParts: string[] = userPrefCookie.substring(PREFIX.length).split('|');

    const part = userPrefParts?.find((part) => part.startsWith(`${key}:`));

    if (!part) {
        return undefined;
    }

    return decodeValue(part.substring(key.length + 1));
};

export const getDistanceFromUserPref = (): number | undefined => {
    const distance = getValueFromUserPref('distance');
    if (distance && parseInt(distance) >= 1) {
        return parseInt(distance);
    }
    return undefined;
};
