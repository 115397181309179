import React, { Fragment } from 'react';
import classNames from 'classnames';
import ErrorBoundary from '../error-boundary';
import NavBar from './nav-bar/nav-bar';
import CategoryNavRibbon from '../category-nav-ribbon/category-nav-ribbon';

const Header = ({ device, experiments }) => {
    const inExperiment = experiments['GTALL-20594'] === 'B' && device === 'mobile';

    return (
        <Fragment>
            <ErrorBoundary>
                <header
                    className={classNames('header', {
                        inExperimentGTALL20594: inExperiment,
                    })}
                >
                    <NavBar />
                    <CategoryNavRibbon />
                </header>
            </ErrorBoundary>
        </Fragment>
    );
};

type Props = {
    device: string;
    experiments: object;
    pageType: string;
};

export default Header;
