/* eslint-disable @typescript-eslint/no-unused-vars, no-underscore-dangle */
import { hasAcceptedToAll } from '../../gdpr/util';
import buildSlotConfig from './build-slot-config';

const fetchBid = (device, pageType, categoryL1, isAdRefresh, window): Promise<void> => {
    return new Promise((res) => {
        if (!window.__tcfapi) {
            res();
            return;
        }

        window.__tcfapi('addEventListener', 2, (tcData, success) => {
            if (!tcData) {
                res();
                return;
            }

            const { eventStatus, vendor, purpose } = tcData;

            if (success) {
                if (eventStatus === 'cmpishown') {
                    res();
                    return;
                }

                const acceptedToAll = hasAcceptedToAll(vendor.consents, purpose.consents);

                if (!acceptedToAll) {
                    res();
                    return;
                }

                const slots = buildSlotConfig(device, pageType, categoryL1, isAdRefresh);
                window.apstag.fetchBids(
                    {
                        slots,
                        timeout: 1500,
                    },
                    function () {
                        window.googletag.cmd.push(function () {
                            window.apstag.setDisplayBids();
                            res();
                        });
                    }
                );
            }
        });
    });
};

export default fetchBid;
