export const isMobileSkinsEnabled = (deviceType, pageType, l1Category, l2Category, experiments) => {
    const inL2Cars =
        experiments['GTALL-25681'] === 'B' &&
        l1Category === 'cars-vans-motorbikes' &&
        l2Category === 'cars';

    const inForSaleAndBusinessServices =
        experiments['GTALL-26602'] === 'B' &&
        ['for-sale', 'business-services'].includes(l1Category);

    return Boolean(
        deviceType === 'mobile' &&
            ['ResultsSearch', 'ResultsBrowse'].includes(pageType) &&
            (inL2Cars || inForSaleAndBusinessServices)
    );
};
