import logger from './logger';

export async function postData(formData, setIsLoading, url) {
    setIsLoading(true);
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        mode: 'cors',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: new URLSearchParams(formData as any),
    });
    return response.json();
}

export async function resendLink(url) {
    await fetch(`/bff-api/register/resend-activation?resendLink=${url}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            logger.error('Resend link error:', error);
        });
}
