import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import EventListener from 'react-event-listener';
import classNames from 'classnames';
import Icon from '../icon/icon';
import Overlay from '../overlay/overlay';
import { DialogContainer, DialogWindow, DialogTitle, DialogClose } from './styles';
import ScrollStopper from '../scroll-stopper/scroll-stopper';

const duration = 300;
/** A dialog/modal which will cover all other content used to focus the user to the contained content */
class Dialog extends PureComponent {
    /**
     * @param {React.MouseEvent} event
     * @param {string} [action]
     */
    close = (event, action) => {
        const dialogContent = document.querySelector('.dialog-content');
        const isInsideDialogContent = dialogContent.contains(event.target);

        const { onClose } = this.props;
        if (!this.dialog.contains(event.target) && !isInsideDialogContent) {
            onClose(event, action);
        }
    };

    handleEscape = (event) => {
        const { onClose } = this.props;
        if (event.key === 'Escape') {
            onClose(event);
        }
    };

    render() {
        const target = typeof document !== 'undefined' && document ? document : 'document';
        const {
            children,
            isOpen,
            hideCloseBtn,
            onClose,
            title,
            closeIconType,
            className,
            containerClassName,
            'data-q': dataQ,
            scrollStopper,
            displayAsSheetOnMweb,
        } = this.props;

        /** @type {React.RefObject<HTMLDivElement>} */
        const rootRef = React.createRef();

        return (
            <>
                {isOpen && <EventListener target={target} onKeyUp={this.handleEscape} />}
                <div ref={rootRef} className={classNames(className)} data-q={dataQ}>
                    <Overlay
                        duration={duration}
                        onClick={(e) => this.close(e, 'background')}
                        isOpen={isOpen}
                    >
                        {isOpen && (
                            <DialogContainer
                                className={classNames(containerClassName, 'dialog-container')}
                            >
                                {scrollStopper && <ScrollStopper dialogRoot={rootRef} />}
                                <DialogWindow
                                    className={classNames(className, 'dialog')}
                                    ref={(ref) => (this.dialog = ref)}
                                    displayAsSheetOnMweb={displayAsSheetOnMweb}
                                >
                                    {title && (
                                        <DialogTitle
                                            data-testid="dialog-title"
                                            className="dialog-title"
                                        >
                                            {title}
                                        </DialogTitle>
                                    )}
                                    <div data-testid="dialog-content" className="dialog-content">
                                        {children}
                                    </div>
                                    {!hideCloseBtn && (
                                        <DialogClose
                                            className="dialog-close"
                                            aria-label="Close"
                                            onClick={(e) => onClose(e, 'action')}
                                            icon={<Icon type={closeIconType} />}
                                            data-q="dialog-close"
                                            data-testid="dialog-close"
                                        />
                                    )}
                                </DialogWindow>
                            </DialogContainer>
                        )}
                    </Overlay>
                </div>
            </>
        );
    }
}

Dialog.defaultProps = {
    children: null,
    /** allow for styling overrides */
    className: null,
    containerClassName: null,
    displayAsSheetOnMweb: false,
    hideCloseBtn: false,
    isOpen: false,
    onClose: () => {},
    title: '',
    closeIconType: 'clear',
    'data-q': null,
    scrollStopper: true,
};

Dialog.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    closeIconType: PropTypes.string,
    containerClassName: PropTypes.string,
    'data-q': PropTypes.string,
    displayAsSheetOnMweb: PropTypes.bool,
    hideCloseBtn: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    scrollStopper: PropTypes.bool,
    title: PropTypes.string,
};

export default Dialog;
