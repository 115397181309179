import React, { useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Button, Container, Grid, Icon } from '@gumtree/ui-library';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { isInExperiment } from '@gumtree/shared/src/experiments/experiments';
import { ExperimentVariant, ExperimentNames } from '@gumtree/shared/src/model/experiment';
import { trackGA4AndNavigate } from '@gumtree/shared/src/util/ga4-shared';
import { getLinkHostname } from '@gumtree/shared/src/util/ga4-bff';

import { trackV2 } from '@gumtree/shared/src/util/track-ga-event';
import { getDomainFromUrl } from '@gumtree/shared/src/util/url-service';
import { css, useTheme } from '@emotion/react';

import { breakpoints, mediaQuery } from '@gumtree/ui-library/src/base/theme';
import type { ShellState, ShellUseDispatch } from '../../reducers/common';
import * as searchBarAct from '../search-bar/search-bar-actions';

import SideMenu from '../side-menu/side-menu';
import UserMenu from '../user-menu/user-menu';

import SearchBar from '../search-bar/search-bar-container';
import {
    NavBarContainer,
    NavLogo,
    NavPostAdButton,
    StyledSearchButton,
    NavLogoWrapper,
    ButtonsWrapper,
    SearchBarWrapper,
    sellMyCarButtonCss,
} from './style';
import NavLoginButton from './nav-bar-login-button';
import NavBarMessagesButton from './nav-bar-messages-button';
import { OnlyShowOnSmallScreen } from '../utils/screens.style';
import LoginModal from '../../login-modal/app';
import { StyledDialog } from '../../login-modal/app.style';
import { useModal } from '../../use-modal';
import { setIsOpen, showForgotPasswordModal } from '../../reducers/common/login-modal';

const StyledGrid = css`
    flex-wrap: wrap;

    ${mediaQuery.from(breakpoints.large)} {
        flex-wrap: nowrap;
    }
`;

export default function NavBar(props: Props) {
    const theme = useTheme();
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [focusSearchBar, setFocusSearchBar] = useState(false);
    const { openDialog, closeDialog } = useModal();
    const { baseConfig, experiments, showSearchBarOnMobile, userData, isOpen } =
        useSelector(selectReduxState, shallowEqual);

    const dispatch = useDispatch() as ShellUseDispatch;
    const toggleSearchModal = () => dispatch(searchBarAct.toggleSearchModal());
    const inExperimentGTC1815 = experiments?.[ExperimentNames.REGISTER_LOGIN_BFF] === 'B';
    const inExperimentGTC2229 =
        experiments?.[ExperimentNames.LOGIN_MODAL] === ExperimentVariant.VARIANT_B;
    const trackIconClick = (event, label, others = {}) => trackV2(event, label, others);

    const loggedIn = userData && userData.userLoggedIn;
    const isMobile = baseConfig.device?.type === 'mobile';

    const showSearchIcon = !showSearchBarOnMobile;

    const isSellCarExperiment =
        baseConfig.category.l2Category === 'cars' &&
        ['VIP', 'ResultsSearch', 'ResultsBrowse'].includes(baseConfig.pageType);
    const inExperimentGTCC921CoreChatRead = isInExperiment(
        experiments,
        ExperimentNames.CORECHAT_READ_BFF,
        ExperimentVariant.VARIANT_B
    );

    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const resetPassword = params.get('reset-password');
        const isRegistrationSuccessful = params.get('registration-successful');

        if (isRegistrationSuccessful) {
            setRegistrationSuccess(true);
            dispatch(setIsOpen(true));
            params.delete('registration-successful');
            const newUrl = `${window.location.pathname}?${params.toString()}`;
            window.history.replaceState({}, '', newUrl);
        } else if (resetPassword) {
            dispatch(setIsOpen(true));
            dispatch(showForgotPasswordModal({ value: true, isReset: true }));
            params.delete('reset-password');
            const newUrl = `${window.location.pathname}?${params.toString()}`;
            window.history.replaceState({}, '', newUrl);
        }
    }, []);

    const openSearchBar = () => {
        closeDialog();
        setFocusSearchBar(true);
    };

    return (
        <NavBarContainer data-q="nav-bar">
            <Container>
                <Grid container item={false}>
                    <Grid css={StyledGrid} item justifyContent="space-between" col={12}>
                        <NavLogoWrapper>
                            <NavLogo
                                linkTo="/"
                                {...qaAttribute('gumtree-logo')}
                                data-testid="gumtree-logo"
                                onClick={(e) =>
                                    trackGA4AndNavigate<GA4.ClickPrimaryLogo>({
                                        href: '/',
                                        mouseEvent: e,
                                        payload: {
                                            event: 'click_primary_logo',
                                            linkDomain: getLinkHostname('/'),
                                            linkText: 'Gumtree',
                                            linkURL: '/',
                                        },
                                    })
                                }
                            />
                        </NavLogoWrapper>
                        <ButtonsWrapper isInGTC2229={inExperimentGTC2229}>
                            {showSearchIcon && (
                                <OnlyShowOnSmallScreen>
                                    <StyledSearchButton
                                        {...qaAttribute('search-bar-toggler')}
                                        aria-label="Search Gumtree.com"
                                        className="search-toggle"
                                        dark
                                        label="Search"
                                        icon={<Icon type="magnifying-glass" />}
                                        onClick={toggleSearchModal}
                                        bottomLabel
                                    />
                                </OnlyShowOnSmallScreen>
                            )}
                            {isSellCarExperiment && !loggedIn ? (
                                <Button
                                    dark
                                    label={isMobile ? 'Sell' : 'Sell my Car'}
                                    icon={<Icon type="car" />}
                                    bottomLabel
                                    href={`${baseConfig.buyerUrl}/sell-my-car`}
                                    onClick={(e) =>
                                        inExperimentGTC2229
                                            ? openDialog(e, 'sell', true)
                                            : trackIconClick('PostAdBegin', 'PostAdHeader')
                                    }
                                    css={sellMyCarButtonCss(theme)}
                                />
                            ) : inExperimentGTC2229 && !loggedIn ? (
                                <NavPostAdButton
                                    dark
                                    data-testid="post-ad-button"
                                    mobileCondition={isMobile}
                                    iconName="sell-outline"
                                    label="Sell"
                                    onClick={(e) => {
                                        openDialog(e, 'sell', true);
                                        trackIconClick('PostAdBegin', 'PostAdHeader');
                                    }}
                                    bottomLabel
                                    isInGTC2229
                                    experiments={experiments}
                                />
                            ) : (
                                <NavPostAdButton
                                    dark
                                    data-testid="post-ad-button"
                                    mobileCondition={isMobile}
                                    label="Sell"
                                    onClick={(e) => {
                                        const url =
                                            !loggedIn && inExperimentGTC1815
                                                ? `${baseConfig.buyerUrl}/login?cb=${baseConfig.bffUrl}/postad/category`
                                                : `${baseConfig.buyerUrl}/postad/category`;
                                        trackIconClick('PostAdBegin', 'PostAdHeader');
                                        trackGA4AndNavigate<GA4.ClickPrimaryNavigation>({
                                            href: url,
                                            mouseEvent: e,
                                            payload: {
                                                event: 'click_primary_navigation',
                                                linkDomain: getDomainFromUrl(url),
                                                linkText: 'Sell',
                                                linkURL: url,
                                                navigationCategory: 'menu',
                                                navigationHeader: undefined,
                                                navigationHierarchy: 'header',
                                            },
                                        });
                                    }}
                                    bottomLabel
                                    experiments={experiments}
                                />
                            )}
                            {loggedIn && <NavBarMessagesButton />}
                            {loggedIn && (
                                <UserMenu
                                    isLoggedIn={loggedIn}
                                    inExperimentGTCC921CoreChatRead={
                                        inExperimentGTCC921CoreChatRead
                                    }
                                    buyerUrl={baseConfig.buyerUrl}
                                    sellerUrl={baseConfig.sellerUrl}
                                    experiments={experiments}
                                />
                            )}
                            {!userData && (
                                <>
                                    {inExperimentGTC2229 ? (
                                        <>
                                            <NavLoginButton
                                                dark
                                                isInGTC2229
                                                showTooltip={false}
                                                icon={<Icon type="person-outline-register" />}
                                                label="Sign up"
                                                qaAttr="hm-registration"
                                                onClick={(e) => {
                                                    openDialog(e, 'register', false);
                                                }}
                                            />
                                            <NavLoginButton
                                                dark
                                                isInGTC2229
                                                showTooltip={false}
                                                icon={<Icon type="person-outline-login" />}
                                                label="Login"
                                                onClick={(e) => {
                                                    openDialog(e, 'login', true);
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <NavLoginButton
                                            dark
                                            isInGTC2229={false}
                                            icon={<Icon type="person" />}
                                            label="Login/Register"
                                            showTooltip
                                            href={
                                                inExperimentGTC1815
                                                    ? `${baseConfig.buyerUrl}/login`
                                                    : `${baseConfig.sellerUrl}/login`
                                            }
                                            onClick={(e) => {
                                                const url = inExperimentGTC1815
                                                    ? `${baseConfig.buyerUrl}/login`
                                                    : baseConfig.loginUrl;
                                                trackV2('HeaderLoginLink');
                                                trackV2('HeaderLoginSelect');
                                                trackGA4AndNavigate<GA4.ClickPrimaryNavigation>({
                                                    href: url,
                                                    mouseEvent: e,
                                                    payload: {
                                                        event: 'click_primary_navigation',
                                                        linkDomain: getDomainFromUrl(url),
                                                        linkText: 'Login/Register',
                                                        linkURL: url,
                                                        navigationCategory: 'menu',
                                                        navigationHeader: undefined,
                                                        navigationHierarchy: 'header',
                                                    },
                                                });
                                            }}
                                        />
                                    )}
                                </>
                            )}
                            {!props.hideSideMenu && (
                                <SideMenu
                                    baseConfig={baseConfig}
                                    isLoggedIn={!!userData}
                                    experiments={experiments}
                                    openDialog={(e) => {
                                        openDialog(e, 'login', false);
                                    }}
                                />
                            )}
                        </ButtonsWrapper>
                        <SearchBarWrapper>
                            <SearchBar outsideFocus={focusSearchBar} />
                        </SearchBarWrapper>
                    </Grid>
                </Grid>
            </Container>
            <StyledDialog isOpen={isOpen} onClose={closeDialog}>
                <LoginModal
                    registrationSuccess={registrationSuccess}
                    openSearchBar={openSearchBar}
                />
            </StyledDialog>
        </NavBarContainer>
    );
}

interface Props {
    hideSideMenu?: boolean;
}

NavBar.defaultProps = {
    hideSideMenu: false,
};

function selectReduxState({
    baseConfig,
    categoryNav,
    searchBar,
    experiments,
    features,
    userData,
    loginModal: { isOpen, showForgotPassword },
}: ShellState) {
    return {
        baseConfig,
        categoryNav,
        showSearchBarOnMobile: searchBar.showSearchBarOnMobile,
        experiments,
        features,
        userData,
        isOpen,
        showForgotPassword
    };
}
