/* eslint-disable no-underscore-dangle */
const clickToCallExtensionTypes = [
    'Ads/LocationExtension',
    'Ads/MeteredCallExtension',
    'Ads/CallExtension',
];

const isClickToCallExtension = (ext) => clickToCallExtensionTypes.includes(ext._type);

const isNumberValid = ({
    businessPhone,
    normalizedPhoneNumber,
    provisionedPhoneNumber,
    phoneNumber,
}) => !!businessPhone || !!normalizedPhoneNumber || !!provisionedPhoneNumber || !!phoneNumber;

export const hasClickToCallExtension = (extensions = []) => extensions.some(isClickToCallExtension);

export const getClickToCallExtensions = (extensions = []) =>
    extensions.filter(isClickToCallExtension);

export const getClickToCallNumbers = (extensions) =>
    extensions
        .filter(isNumberValid)
        .map(
            (
                {
                    businessPhone,
                    businessPhoneLink,
                    normalizedPhoneNumber,
                    provisionedPhoneNumber,
                    phoneLink,
                    phoneNumber,
                    phoneNumberLink,
                },
                index
            ) => ({
                number:
                    businessPhone || normalizedPhoneNumber || provisionedPhoneNumber || phoneNumber,
                tracking: businessPhoneLink || phoneLink || phoneNumberLink,
                id: index,
            })
        );
