/* eslint-disable react/require-default-props */
import React from 'react';
import styled from '@emotion/styled';

import { Grid, Image } from '@gumtree/ui-library';
import {
    fontSizes,
    fontWeights,
    colors,
    breakpoints,
    mediaQuery,
} from '@gumtree/ui-library/src/base/theme';

import SubNavPanelSection from './sub-nav-panel-section';
import carsGuideImage from './images/cars-guide.png';
import { onClickFunction } from './sub-nav-panel-section-item';

const StyledSubNavPanelSection = styled(SubNavPanelSection)`
    ${mediaQuery.from(breakpoints.medium)} {
        border-left: 1px solid rgba(0, 0, 0, 0.3);
        padding-left: 32px;
    }
`;

const Figcaption = styled.figcaption`
    font-size: ${fontSizes.smaller};
    margin-bottom: 4px;
`;

const ImageTitle = styled.p`
    margin: 0;
    font-size: ${fontSizes.base};
    font-weight: ${fontWeights.bold};
    color: ${colors.bark};
`;

const CardLink = styled.a`
    &:hover *:not(${Figcaption}) {
        text-decoration: underline;
    }
`;

interface Props {
    onItemClick?: onClickFunction;
}

const SubNavPanelSectionMotors = ({ onItemClick }: Props) => (
    <>
        <StyledSubNavPanelSection
            title="Discover more in our guides"
            items={[
                {
                    name: 'Car Guides',
                    path: 'https://www.gumtree.com/info/cars/',
                },
                {
                    name: 'Car Reviews',
                    path: 'https://www.gumtree.com/info/cars/reviews-hub/',
                },
                {
                    name: 'Best Cars',
                    path: 'https://www.gumtree.com/info/cars/best-of-hub/',
                },
                {
                    name: 'Car Advice',
                    path: 'https://www.gumtree.com/info/cars/advice-hub/',
                },
            ]}
            numberOfItemColumns={1}
            col={12}
            colL={5}
            colXl={6}
            colXxl={4}
            onItemClick={onItemClick}
         />
        <Grid item colXxl={3} hiddenUntilXxl>
            <CardLink href="https://www.gumtree.com/info/cars/">
                <Image src={carsGuideImage} alt="Discover more in our guides" />
                <Figcaption>Cars guides & advice</Figcaption>
                <ImageTitle>Discover more in our guides</ImageTitle>
            </CardLink>
        </Grid>
    </>
);

export default SubNavPanelSectionMotors;
